footer {
    max-width: 1200px;
    margin: auto;
    font-family: "Roboto", sans-serif;

    >div {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

        p {
            margin: 1em;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }

        svg {
            margin: 0 1em 0 0;
        }
    }

    h2,
    p {
        color: white;
        text-align: center;
    }

    h2 {
        font-weight: normal;
        font-family: "chalk";
    }

    .socials {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0;
        list-style-type: none;

        svg {
            margin: 0 1em;
        }
    }

    svg {
        background-color: #fce531;
        border-radius: 50%;
        height: 2em;
        width: 2em;
        fill: #343638;
        padding: 0.2em;
    }

    svg:hover {
        fill: white;
    }
}